import { render, staticRenderFns } from "./Authentication.vue?vue&type=template&id=1f5a1abe"
import script from "./Authentication.vue?vue&type=script&lang=js"
export * from "./Authentication.vue?vue&type=script&lang=js"
import style0 from "./Authentication.vue?vue&type=style&index=0&id=1f5a1abe&prod&lang=scss"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports