<template>
    <page
        id="dashboard"
        :title="translate('Dashboard')"
        :status="page_status"
        :waiting="waiting"
        @statused="statused"
    >
        <template v-slot:title>
            <h1>
                <span>
                    {{ translate('Dashboard') }}
                </span>
                <stat-button
                    :count="errors_warnings.errors.count"
                    :label="translate('Errors')"
                    color="error"
                    glyph="bomb"
                    class="ml-2"
                    @click.prevent="errors_warnings.errors.refresh++"
                />
                <stat-button
                    :count="errors_warnings.warnings.count"
                    :label="translate('Warnings')"
                    color="warning"
                    glyph="exclamation-triangle"
                    class="ml-2"
                    @click.prevent="errors_warnings.warnings.refresh++"
                />
                <stat-button
                    v-if="errors_warnings.links.count"
                    :count="errors_warnings.links.count"
                    :label="translate('Broken Links')"
                    color="secondary"
                    glyph="unlink"
                    class="ml-2"
                    @click.prevent="errors_warnings.links.refresh++"
                />
                <stat-button
                    v-if="errors_warnings.images.count"
                    :count="errors_warnings.images.count"
                    :label="translate('Missing Images')"
                    color="secondary"
                    glyph="image"
                    class="ml-2"
                    @click.prevent="errors_warnings.images.refresh++"
                />
            </h1>
        </template>

        <list-stats
            v-if="is_at_least_administrator"
            entity="app"
            :stat-ids="[
                'total-clients',
                'total-projects',
                'total-sites',

                'total-plans',
                'TYPE_MAINTENANCE-plans',
                // 'TYPE_CONTENT_CREATION-plans',

                'TYPE_ACCESSIBILITY-plans',
                // 'TYPE_GG_BIZ_PROFILE-plans',
                'TYPE_ANALYTICS-plans',
                // 'TYPE_SEO-plans',
                'TYPE_FORMSGUARD-plans',

                'total-active-projects-value',
                'total-income',
                'total-license-revenue',

                'total-license-cost',
                'total-hosting-costs',
                'total-expense'
            ]"
            :date-range="current_date_range"
        >
            <template
                v-slot:default="{ id, waiting, data }"
            >
                <statistic-card
                    v-if="!!data"
                    :id="id"
                    :link-id="card_link_id(id)"
                    :data="data"
                    :from="current_date_range.from"
                    :to="current_date_range.to"
                    :date-format="is_custom_date_range ? 'DD/MM/YY' : 'MM/YY'"
                    :waiting="waiting"
                    @click="show_card_detail"
                >
                    <template v-slot:footer>
                        <div
                            :class="{ 'd-invisible': id !== 'total-plans' }"
                            class="btn-group btn-group-block"
                        >
                            <default-button
                                v-for="date_range in date_ranges"
                                :key="date_range.id"
                                size="xs"
                                :active="date_range.id === date_range_id"
                                :disabled="(date_range.id === date_range_id) && (date_range.id !== 'custom')"
                                @click.prevent.stop="pick_date_range(date_range.id)"
                            >
                                {{ date_range.label }}
                            </default-button>
                        </div>
                    </template>
                </statistic-card>
            </template>
        </list-stats>

        <base-dashboard
            v-if="is_at_least_administrator"
            :site-id="0"
            title=""
            :show-errors="errors_warnings.errors.refresh"
            :show-warnings="errors_warnings.warnings.refresh"
            :show-links="errors_warnings.links.refresh"
            :show-images="errors_warnings.images.refresh"
            @loading="stop_waiting"
            @loaded="refresh_errors_warnings"
        />

        <modal-dialog
            id="graph-viewer"
            :show.sync="viewing"
        >
            <template v-slot:title>
                <span class="h5">
                    {{ translate("Maintenance Plans") }}
                </span>
            </template>

            <apexchart
                v-if="viewing && viewing_data"
                :id="`${viewing_id}-full`"
                type="line"
                :options="viewing_data.options"
                :series="viewing_data.series"
            />
        </modal-dialog>

        <modal-dialog
            id="custom-date-range-picker"
            :show.sync="customizing_date_range"
        >
            <template v-slot:title>
                <span class="h5">
                    {{ translate("Custom Date Range") }}
                </span>
            </template>

            <div class="my-16">
                <form-date-input
                    id="from"
                    name="from"
                    v-model="custom_date_range.from"
                    position="over"
                    :required="true"
                    :placeholder="translate('From')"
                    calendar-size="sm"
                >
                    <template v-slot:label>{{ translate("From") }}</template>
                </form-date-input>
                <form-date-input
                    id="to"
                    name="to"
                    v-model="custom_date_range.to"
                    position="over"
                    :required="true"
                    :placeholder="translate('To')"
                    calendar-size="sm"
                >
                    <template v-slot:label>{{ translate("To") }}</template>
                </form-date-input>
            </div>

            <template v-slot:footer>
                <div class="text-center">
                    <default-button
                        class="mr-2"
                        @click.prevent="customizing_date_range = false"
                    >
                        {{ $root.translate("Cancel") }}
                    </default-button>
                    <default-button
                        color="primary"
                        class="ml-2"
                        @click.prevent="set_custom_date_range"
                    >
                        {{ $root.translate("Set") }}
                    </default-button>
                </div>
            </template>
        </modal-dialog>
    </page>
</template>

<script type="text/javascript">
import { mapGetters } from "vuex"

import { is_page, profile_utilities } from "@/nibnut/mixins"

import { ModalDialog, FormDateInput, DefaultButton } from "@/nibnut/components"
import { ListStats, BaseDashboard, StatButton } from "@/custom/components"
import StatisticCard from "./dashboard/StatisticCard"
import VueApexCharts from "vue-apexcharts"
/*
const random = (min, max) => {
    return Math.floor(Math.random() * (max - min + 1)) + min
}
*/
export default {
    name: "Dashboard",
    mixins: [is_page, profile_utilities],
    components: {
        ModalDialog,
        FormDateInput,
        DefaultButton,
        ListStats,
        BaseDashboard,
        StatButton,
        StatisticCard,
        Apexchart: VueApexCharts
    },
    mounted () {
        this.start_waiting()
    },
    watch: {
        profile_id: "start_waiting",
        $route: "start_waiting"
    },
    methods: {
        statused (status) {
            this.page_status = status
        },
        start_waiting () {
            if(this.profile_id && !this.is_at_least_administrator) this.$router.replace({ name: "license.list" })
            this.waiting = true
        },
        stop_waiting (waiting) {
            if(!waiting) this.waiting = waiting
        },
        refresh_errors_warnings ({ errors, warnings, links, images }) {
            this.errors_warnings.errors.count = errors
            this.errors_warnings.warnings.count = warnings
            this.errors_warnings.links.count = links
            this.errors_warnings.images.count = images
        },
        card_link_id (card_id) {
            return `card-navigation-${card_id}`
        },
        show_card_detail (card_id, data, chart_options, chart_data) {
            const node = document.getElementById(this.card_link_id(card_id))
            if(node) node.click()
            else {
                chart_options.chart.sparkline.enabled = false
                this.viewing_id = card_id
                this.viewing_data = { options: chart_options, series: chart_data }
                this.viewing = true
            }
        },
        pick_date_range (date_range_id) {
            if(date_range_id !== "custom") this.date_range_id = date_range_id
            else {
                const current_date_range = this.current_date_range
                this.custom_date_range.from = current_date_range.from
                this.custom_date_range.to = current_date_range.to
                this.customizing_date_range = true
            }
        },
        set_custom_date_range () {
            const current_date_range = this.date_ranges.custom
            current_date_range.from = this.$moment(this.custom_date_range.from)
            current_date_range.to = this.$moment(this.custom_date_range.to)
            this.date_range_id = "custom"
            this.customizing_date_range = false
        }
    },
    computed: {
        ...mapGetters(["entity_records"]),
        date_ranges () {
            const to = this.$moment().endOf("month")
            return {
                custom: { id: "custom", label: this.translate("Custom"), from: null, to: null },
                "5y": { id: "5y", label: this.translate("5 Years"), from: this.$moment().startOf("month").subtract(5, "years"), to },
                "3y": { id: "3y", label: this.translate("3 Years"), from: this.$moment().startOf("month").subtract(3, "years"), to },
                "2y": { id: "2y", label: this.translate("2 Years"), from: this.$moment().startOf("month").subtract(2, "years"), to },
                "1y": { id: "1y", label: this.translate("1 Year"), from: this.$moment().startOf("month").subtract(1, "year"), to }
            }
        },
        current_date_range () {
            return this.date_ranges[this.date_range_id]
        },
        is_custom_date_range () {
            return this.date_range_id === "custom"
        }
    },
    data () {
        return {
            bootstraped: false,
            errors_warnings: {
                errors: { count: 0, refresh: 0 },
                warnings: { count: 0, refresh: 0 },
                links: { count: 0, refresh: 0 },
                images: { count: 0, refresh: 0 }
            },
            show_errors: 0,
            warnings_count: 0,
            show_warnings: 0,
            viewing: false,
            viewing_id: null,
            viewing_data: null,
            date_range_id: "1y",
            custom_date_range: { from: null, to: null },
            customizing_date_range: false
        }
    }
}
</script>

<style lang="scss">
#dashboard {
    .status-badge {
        display: inline-block;
        position: relative;
        top: -0.2rem;
        border-radius: 50%;
        padding: 0 0.25em;
        font-size: 1rem;
    }
    .card-header {
        position: relative;
        & > .card-subtitle a {
            color: inherit;
        }
        & > .text-small.text-gray {
            margin-left: -0.8rem;
            margin-right: -0.8rem;
            min-height: 1rem;

            & > div > div {
                width: 100%;
            }
        }
    }
    .card-footer {
        padding: 0;
    }
}
</style>
